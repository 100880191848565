import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Episode } from 'state/Podcast/types';
import { Helmet } from 'react-helmet';
import { link as playerLink, meta as playerMeta } from 'heads/player';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';

export type Props = {
  episode: Episode | null;
  fbAppId?: string;
  fbPages?: string;
  imgUrl: string;
  pathName: string | null | undefined;
  showTitle: string;
  siteUrl: string;
};

function PodcastEpisodeHead({
  episode,
  imgUrl,
  pathName,
  showTitle,
  siteUrl,
}: Props) {
  const translate = useTranslate();

  if (!episode) return null;

  const { description, title, podcastId, id } = episode;
  const pagePath = pathName || '';
  const episodeTitle = episode ? title : null;
  const { height, fixedWidth: width }: WidgetDimensions =
    WIDGET_DIMENSIONS.PODCAST_EPISODE ?? {};
  const episodeDescription = description.replace(/\n+/g, ' ');
  const opsString = '?ops=gravity(%22center%22),ratio(16,9),resize(1230,0)';
  const imageUrl = episode?.imageUrl ?? imgUrl;

  return (
    <>
      <GenericHead
        deeplink={`goto/talk/show/${podcastId}/episode/${id}`}
        description={episodeDescription}
        facebookImage={`${imageUrl}?ops=contain(1200,630)`}
        image={`${imageUrl}${opsString}`}
        legacyDeeplink={`play/custom/talk/show/${podcastId}/episode/${id}`}
        title={`${title} - ${showTitle}`}
        twitterImage={imageUrl}
        urlEncodeImage={false}
      />
      <Helmet
        link={playerLink({ pagePath, siteUrl, title })}
        meta={[
          {
            content: translate(
              'Listen to {episodeTitle} | {showTitle} | Podcasts',
              {
                episodeTitle,
                showTitle,
              },
            ),
            itemProp: 'name',
          },
          {
            content: translate(
              '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart',
              { episodeTitle, showTitle },
            ),
            name: 'keywords',
          },
          ...playerMeta({ pagePath, siteUrl, height, width }),
        ]}
      />
    </>
  );
}

export default PodcastEpisodeHead;
